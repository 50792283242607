import { styled } from '@material-ui/core';
import UpdateBillingCartSummary from 'components/CartSummary/UpdateBillingCartSummary';
import BadgeList from 'components/Badging/BadgeList';

const StyledBox = styled('aside')({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  gridArea: 'sidebar',
  borderRadius: '5px',
  gap: '10px',
  textAlign: 'center',
});

const UpdateBillingSidebar = () => {
  return (
    <StyledBox>
      <UpdateBillingCartSummary />
      <BadgeList />
    </StyledBox>
  );
};

export default UpdateBillingSidebar;
