import { useEffect, useState } from 'react';
import {
  UBSFormikBillingValues,
  FormStatus,
  StripeUBSBillingPayload,
  SetupIntentBillingPayload,
} from 'models/Billing';
import * as Yup from 'yup';
import { ZipRegex } from 'utils/validationUtils';
import { Box, CircularProgress, styled, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useBilling } from 'hooks/useBilling';
import { useStripeContext } from 'contexts/StripeContext';
import { UBSInitialValues } from 'pages/signup-payment/SignupPaymentPage';
import ErrorAlert from 'components/ErrorAlert';
import FormTextField from 'components/BillingForm/FormTextField';
import CountryRegionVatSelector from 'components/BillingForm/CountryRegionVatSelector';
import ReloadModal from 'components/BillingForm/ReloadModal';
import CancelButton from 'components/CancelButton';
import UpdateButton from 'components/UpdateButton';
import { useURLParams } from 'hooks/useURLParams';
import { useUBSStripe } from 'hooks/useStripe';
import UpdateBillingSidebar from 'components/UpdateBillingSidebar';
import { colors } from 'constants/colors';
import { PageContainer } from '../signup-payment/SignupPaymentPage';

const validationSchema = Yup.object({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  billingAddress: Yup.string().required(`Billing address is required`),
  region: Yup.string().required(`State / Region is required`),
  city: Yup.string().required(`City is required`),
  zip: Yup.string().when('country', {
    is: 'US',
    then: Yup.string()
      .matches(ZipRegex, `Zip / Postal code must be 5 digits`)
      .required(`Zip / Postal code is required`),
    otherwise: Yup.string().required(`Zip / Postal code is required`),
  }),
});

const StyledForm = styled(Form)(({ theme }) => ({
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
  borderRadius: '16px',
}));

const WrapperBox = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  gridArea: 'form',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.polar10}`,
  padding: '30px 25px',
  borderRadius: '16px',
});

const UpdateStyledBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  paddingTop: '10px',
  justifyContent: 'flex-end',
});

export const StyledBox = styled(Box)({
  paddingTop: '10px',
});

const CustomPaymentElement = styled(PaymentElement)({
  paddingBottom: '30px',
});

export const UpdatePageContainer = styled(PageContainer)(({ theme }) => ({
  gap: '30px',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: 'auto 1fr',
  gridTemplateAreas: `
    'title title'
    'form sidebar'
  `,

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '90px auto',
    gridTemplateAreas: `
      'title'
      'form'
      'sidebar'
    `,
  },
}));

const StripeUBSUpdatePaymentPage = ({ portalInvoicePageUrl }: { portalInvoicePageUrl: string }) => {
  const { transactionDetails } = useTransactionDetails();
  const stripe = useStripe();
  const elements = useElements();
  const { stripeTransactionPatch } = useUBSStripe();
  const { sendBillingInfo } = useBilling();
  const { clientSecret, stripePromise } = useStripeContext();
  const clientSecretParam = useURLParams('setup_intent');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [ReloadModalOpen, setReloadModalOpen] = useState(false);

  useEffect(() => {
    // Handle Stripe success redirect
    if (transactionDetails?.stripe_setup_intent) {
      const handleStripeRedirect = async () => {
        const stripe = await stripePromise;
        if (!stripe) {
          console.error('Stripe has not been loaded.');
          return;
        }

        const { setupIntent, error } = await stripe.retrieveSetupIntent(
          String(transactionDetails?.stripe_setup_intent),
        );

        if (error) {
          console.error(error);
        }

        if (setupIntent?.status === 'succeeded') {
          stripeTransactionPatch(
            {
              stripe_payment_method_id: setupIntent?.payment_method,
              cc_token: 'empty',
            } as SetupIntentBillingPayload,
            {
              onSuccess: async () => {
                window.location.href = `${portalInvoicePageUrl}`;
              },
              onError: async () => {
                setErrorMessage('Unable to redirect. Please reload the page.');
              },
            },
          );
        }
      };

      handleStripeRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDetails]);

  const createStripeIntent = async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
    if (stripe && elements) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href, // Redirect to this page after payment
          payment_method_data: {
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
              phone: values.phoneNumber,
              address: {
                line1: values.billingAddress,
                line2: values.billingAddress2,
                city: values.city,
                country: values.country,
                state: values.region,
                postal_code: values.zip,
              },
            },
          },
        },
      });
      if (error) {
        helpers.setStatus(FormStatus.Error);
        setErrorMessage(error.message);
      }
    }
  };

  const handleReloadModalClose = () => {
    setReloadModalOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setReloadModalOpen(true);
    }, 840000);
  }, []);

  const handleFormSubmit = async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
    helpers.setStatus(FormStatus.Submitting);
    setErrorMessage('');
    const payload: StripeUBSBillingPayload = {
      address1: values.billingAddress,
      address2: values.billingAddress2,
      city: values.city,
      state: values.region,
      country: values.country,
      zip: values.zip,
      vat_id: values.vatId,
      billing_email: values.email,
      billing_first_name: values.firstName,
      billing_last_name: values.lastName,
      email: values.accountEmail || transactionDetails.email,
      telephone: values.phoneNumber,
      company: values.company,
      status: 'pending_payment',
      stripe_setup_intent: clientSecret,
    };
    sendBillingInfo(payload, {
      onSuccess: async () => {
        createStripeIntent(values, helpers);
      },
      onError: async () => {
        // Set error message/state?
      },
    });
  };

  //Show loading screen while redirect logic is happening
  if (clientSecretParam) {
    return (
      <CircularProgress
        size={96}
        color="inherit"
        style={{
          margin: 'auto',
        }}
      />
    );
  }

  return (
    <UpdatePageContainer>
      <Typography variant="h3" style={ { marginBottom: 0 } }>Update payment information</Typography>
      <WrapperBox>
        <Formik initialValues={UBSInitialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
          <>
            <StyledBox>
              <ErrorAlert message={errorMessage} />
              <StyledForm id="billingForm">
                <CustomPaymentElement options={{ fields: { billingDetails: 'never' } }} />
                <FormTextField label="First name" name="firstName" maxChars={256} />
                <FormTextField label="Last name" name="lastName" maxChars={64} />
                <FormTextField label="Email" name="email" maxChars={64} />
                <FormTextField label="Billing address" name="billingAddress" maxChars={256} />
                <FormTextField label="Billing address 2 (optional)" name="billingAddress2" maxChars={64} />
                <CountryRegionVatSelector />
                <FormTextField label="City" name="city" maxChars={64} />
                <FormTextField label="Zip / Postal code" name="zip" maxChars={64} />
              </StyledForm>
              <ReloadModal open={ReloadModalOpen} handleClose={handleReloadModalClose} />
            </StyledBox>
            <UpdateStyledBox>
              <CancelButton portalInvoicePageUrl={portalInvoicePageUrl} />
              <UpdateButton />
            </UpdateStyledBox>
          </>
        </Formik>
      </WrapperBox>
      <UpdateBillingSidebar />
    </UpdatePageContainer>
  );
};

export default StripeUBSUpdatePaymentPage;
