import { FormikErrors } from 'formik';

export interface BillingPayload {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string | number;
  cc_token: string | undefined;
  vat_id?: string;
  cardholder_name: string;
}

export interface UBSBillingPayload extends Omit<BillingPayload, 'cc_token' | 'cardholder_name' | 'vat_id'> {
  transientTokenJwt?: string | undefined;
  referenceId?: string | undefined;
  vatId?: string;
  firstName?: string;
  lastName?: string;
  cardType: string | undefined;
  email: string;
  accountFirstName?: string;
  accountLastName?: string;
  accountEmail?: string | null;
  company?: string;
  phoneNumber?: string;
  totalAmount: string;
  currency: string;
  status?: string;
}

export interface StripeUBSBillingPayload {
  vat_id?: string;
  billing_first_name?: string;
  billing_last_name?: string;
  billing_email: string;
  first_name?: string;
  last_name?: string;
  email?: string | null;
  company?: string;
  telephone?: string;
  status?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string | number;
  stripe_setup_intent?: string;
  stripe_payment_id?: string;
  stripe_customer_id?: string;
}

export interface SetupIntentBillingPayload {
  stripe_setup_intent: string;
  status: string;
  stripe_payment_method_id: string;
  cc_token: string;
  card: {
    masked_number: string;
    expiration_date: {
      month: string;
      year: string;
    };
    type: string;
  };
}

export interface StripeCustomerPayload {
  name: string;
  email: string;
  stripeId: string;
}

export interface FlywheelBillingPayload {
  cc_token: string | undefined;
  nickname?: string;
}

export interface TermsOnlyPayload {
  stripe_customer_id?: string | null;
}

export enum FormStatus {
  Loading = 'loading',
  Submitting = 'submitting',
  Error = 'error',
}

export interface BillingError {
  user_actionable: boolean;
  message: string;
}

export interface FormikBillingValues {
  billingAddress: string;
  billingAddress2: string;
  city: string;
  region: string;
  country: string;
  zip: string;
  vatId?: string;
  cardholderName: string;
  creditCardExpiryDate?: string;
  acceptedTerms?: boolean;
}

export interface UBSFormikBillingValues extends Omit<FormikBillingValues, 'cardholderName'> {
  firstName?: string;
  lastName?: string;
  email: string;
  cardType: { name: string }[];
  accountFirstName?: string;
  accountLastName?: string;
  accountEmail?: string | null;
  phoneNumber?: string;
  company?: string;
}

export interface UBSFormikInitialBillingValues extends Omit<UBSFormikBillingValues, 'cardType'> {}

export interface UBSFormikInitialTermsOnlyValues
  extends Omit<
    FormikBillingValues,
    | 'billingAddress'
    | 'billingAddress2'
    | 'city'
    | 'region'
    | 'country'
    | 'zip'
    | 'vatId'
    | 'cardholderName'
    | 'creditCardExpiryDate'
  > {}

export interface CybersourceErrors extends FormikErrors<any> {
  creditCard: string;
  creditCardExpiryDate: string;
}

export interface CybersourcePayerAuthSetup {
  tokenInformation: { transientToken: string };
  clientReferenceInformation: { code: string };
  card: { type: string; expirationMonth: string; expirationYear: string | undefined; number: string };
}
export interface CybersourcePayerAuthResponse {
  clientReferenceInformation: { code: string };
  consumerAuthenticationInformation: {
    accessToken: string;
    deviceDataCollectionUrl: string;
    referenceId: string;
    token: string;
  };
  id: string;
  status: string;
  submitTimeUtc: string;
}

export interface CybersourcePayerAuthEnrollment {
  orderInformation: {
    amountDetails: {
      currency: string;
      totalAmount: string;
    };
    billTo: {
      address1?: string;
      address2?: string;
      administrativeArea?: string;
      country?: string;
      locality?: string; //city
      firstName?: string;
      lastName?: string;
      phoneNumber?: string;
      email?: string;
      postalCode?: string;
    };
  };
  paymentInformation?: {
    card?: { type: string; expirationMonth: string; expirationYear: string | undefined; number: string };
  };
  consumerAuthenticationInformation: {
    referenceId?: string;
    referenceUrl?: string;
    returnUrl?: string;
    challengeCode: string;
    deviceChannel: string;
  };
  tokenInformation: { transientToken?: string };
  clientReferenceInformation: { code?: string };
  deviceInformation: {
    httpBrowserColorDepth: string;
    httpBrowserJavaEnabled: string;
    httpBrowserJavaScriptEnabled: string;
    httpBrowserLanguage: string;
    httpBrowserScreenHeight: string;
    httpBrowserScreenWidth: string;
    httpBrowserTimeDifference: string;
    userAgentBrowserValue: string;
    fingerprintSessionId: string;
  };
}

export interface CybersourcePayerAuthEnrollmentResponse {
  consumerAuthenticationInformation: {
    eci: string;
    veresEnrolled: string;
    referenceId: string;
    paresStatus: string;
    challengeRequired: string;
    acsUrl: string;
    accessToken: string;
    stepUpUrl?: string;
    pareq: string;
    authenticationTransactionId: string;
  };
  clientReferenceInformation: { code?: string };
  status: string;
}
export interface CybersourcePayerAuthValidation {
  orderInformation: {
    amountDetails: {
      currency: string;
      totalAmount: string;
    };
  };
  consumerAuthenticationInformation: {
    authenticationTransactionId?: string;
  };
  tokenInformation: { transientToken?: string };
  clientReferenceInformation: { code?: string };
}

export interface CybersourceLogging {
  transactionId: string;
  logLevel: string;
  error?: string;
  message?: string;
}

export interface CybersourcePayerValidationResponse {
  consumerAuthenticationInformation: {
    eci: string;
    veresEnrolled: string;
    referenceId: string;
    paresStatus: string;
    challengeRequired: string;
    acsUrl: string;
    accessToken: string;
    stepUpUrl?: string;
    pareq: string;
  };
  clientReferenceInformation: { code?: string };
  status: string;
}

export interface CybersourcePareqResponse {
  messageType: string;
  messageVersion: string;
  threeDSServerTransID: string;
  acsTransID: string;
  challengeWindowSize: string;
}

export interface ChallengeWindowSize {
  size: string;
  width: number;
  height: number;
}

export interface StripeClientSecretRequest {
  stripeId?: string | null;
}

export interface ClientSecretResponse {
  stripeId: string;
  clientSecret: string;
  stripePublishableKey: string;
}
