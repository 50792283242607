import { useState, useEffect } from 'react';
import ProgressStepper from 'components/ProgressStepper';
import { Formik, FormikHelpers, Form, useFormikContext } from 'formik';
import ErrorAlert from 'components/ErrorAlert';
import Sidebar from 'components/Sidebar';
import { colors } from 'constants/colors';
import { merge } from 'lodash';
//import TermsCopyContainer from 'components/TermsCopyContainer';
import { useTransaction } from 'hooks/useTransaction';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import {
  UBSInitialValues,
  UBSSelfServeValidationSchema,
  UBSValidationSchema,
  PageContainer,
  initialTermsOnlyValues,
  termsOnlyValidationSchema,
} from './SignupPaymentPage';
import {
  UBSFormikBillingValues,
  FormStatus,
  SetupIntentBillingPayload,
  StripeUBSBillingPayload,
  StripeCustomerPayload,
  UBSFormikInitialTermsOnlyValues,
  TermsOnlyPayload,
} from 'models/Billing';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useBillingInfo } from 'hooks/useBillingInfo';
import { UBSBillingInfo } from 'models/BillingInfo';
import { useBilling } from 'hooks/useBilling';
import setSegmentCookie from 'utils/CybersourceSetSegmentCookie';
import { CircularProgress, styled, Typography } from '@material-ui/core';
import FormTextField from 'components/BillingForm/FormTextField';
import ReloadModal from 'components/BillingForm/ReloadModal';
import CountryRegionVatSelector from 'components/BillingForm/CountryRegionVatSelector';
import { useStripeContext } from 'contexts/StripeContext';
import { useURLParams } from 'hooks/useURLParams';
import { useUBSStripe } from 'hooks/useStripe';
import TermsCopyContainer from 'components/TermsCopyContainer';

const StyledBox = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  gridArea: 'form',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.polar10}`,
  padding: '30px 25px',
  borderRadius: '16px',
});

const StyledForm = styled(Form)(({ theme }) => ({
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
  borderRadius: '16px',
}));

const StyledAccountHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

const StyledBillingHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

const CustomPaymentElement = styled(PaymentElement)({
  paddingBottom: '30px',
});

const StripeUBSPaymentPage = ({ portalConfirmationPageUrl }: { portalConfirmationPageUrl: string }) => {
  const { transactionDetails } = useTransactionDetails();
  const { transaction } = useTransaction();
  const { billingInfo } = useBillingInfo();
  const stripe = useStripe();
  const elements = useElements();
  const { stripeTransactionPatch, stripeUpdateCustomer } = useUBSStripe();
  const { sendBillingInfo } = useBilling();
  const { clientSecret, stripePromise, stripeCustomerId } = useStripeContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const isPendingSalesOrderWithoutCc = transactionDetails?.signup_code === 'pending_sales_order_without_cc';
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const accountDetailsAreNotPrepopulated = !(transactionDetails?.account_details_prepopulated === true);
  const clientSecretParam = useURLParams('setup_intent');
  const [ReloadModalOpen, setReloadModalOpen] = useState(false);

  useEffect(() => {
    // Handle Stripe success redirect
    if (transaction && transactionDetails?.stripe_setup_intent) {
      const handleStripeRedirect = async () => {
        const stripe = await stripePromise;
        if (!stripe) {
          console.error('Stripe has not been loaded.');
          return;
        }

        const { setupIntent, error } = await stripe.retrieveSetupIntent(
          String(transactionDetails?.stripe_setup_intent),
        );

        if (error) {
          console.error(error);
        }

        if (setupIntent?.status === 'succeeded') {
          stripeTransactionPatch(
            {
              stripe_payment_method_id: setupIntent?.payment_method,
              cc_token: 'empty',
            } as SetupIntentBillingPayload,
            {
              onSuccess: async () => {
                setSegmentCookie(transaction, transactionDetails);
                window.location.href = `${portalConfirmationPageUrl}?email=${encodeURIComponent(
                  transactionDetails.email || '',
                )}&transaction_id=${transactionDetails.id}`;
              },
              onError: async () => {
                setErrorMessage('Unable to redirect. Please reload the page and try again.');
              },
            },
          );
        }
      };

      handleStripeRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDetails, transaction]);

  const handleReloadModalClose = () => {
    setReloadModalOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setReloadModalOpen(true);
    }, 840000);
  }, []);

  useEffect(() => {
    if (billingInfo) {
      merge(UBSInitialValues, { email: (billingInfo as UBSBillingInfo)?.email || '' });
    }
  }, [billingInfo]);

  const createStripeIntent = async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
    if (stripe && elements) {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href, // Redirect to this page after payment
          payment_method_data: {
            billing_details: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
              phone: values.phoneNumber,
              address: {
                line1: values.billingAddress,
                line2: values.billingAddress2,
                city: values.city,
                country: values.country,
                state: values.region,
                postal_code: values.zip,
              },
            },
          },
        },
      });
      if (error) {
        helpers.setStatus(FormStatus.Error);
        setErrorMessage(error.message);
      }
    }
  };

  const handleFormSubmit = async (values: UBSFormikBillingValues, helpers: FormikHelpers<UBSFormikBillingValues>) => {
    helpers.setStatus(FormStatus.Submitting);
    setErrorMessage('');

    const payload: StripeUBSBillingPayload = {
      address1: values.billingAddress,
      address2: values.billingAddress2,
      city: values.city,
      state: values.region,
      country: values.country,
      zip: values.zip,
      vat_id: values.vatId,
      billing_email: values.email,
      billing_first_name: values.firstName,
      billing_last_name: values.lastName,
      status: 'pending_payment',
      stripe_setup_intent: clientSecret,
      stripe_customer_id: stripeCustomerId,
    };
    if (accountDetailsAreNotPrepopulated && isEsmSelfServe) {
      payload.first_name = values.accountFirstName;
      payload.last_name = values.accountLastName;
      payload.telephone = values.phoneNumber;
      payload.company = values.company;
      payload.email = values.accountEmail;
    }

    const updateCustomerPayload: StripeCustomerPayload = {
      stripeId: stripeCustomerId,
      email: values.email,
      name: `${values.firstName} ${values.lastName}`,
    };

    stripeUpdateCustomer(updateCustomerPayload, {
      onSuccess: async () => {
        sendBillingInfo(payload, {
          onSuccess: async () => {
            createStripeIntent(values, helpers);
          },
          onError: async () => {
            helpers.setStatus(FormStatus.Error);
            setErrorMessage('Failed to complete transaction. Please contact support or try again.');
          },
        });
      },
      onError: async () => {
        helpers.setStatus(FormStatus.Error);
        setErrorMessage('Failed to update customer. Please try again.');
      },
    });
  };

  //Show loading screen while redirect logic is happening
  if (clientSecretParam && !errorMessage) {
    return (
      <CircularProgress
        size={96}
        color="inherit"
        style={{
          margin: 'auto',
        }}
      />
    );
  }

  // Display terms & conditions if needed
  if (isPendingSalesOrderWithoutCc) {
    return (
      <PageContainer>
        {/* Should ProgressStepper be removed? Or changed for T&C? */}
        <ProgressStepper />
        <Formik
          initialValues={initialTermsOnlyValues}
          validationSchema={termsOnlyValidationSchema}
          onSubmit={async (
            values: UBSFormikInitialTermsOnlyValues,
            helpers: FormikHelpers<UBSFormikInitialTermsOnlyValues>,
          ) => {
            const payload: TermsOnlyPayload = {
              stripe_customer_id: stripeCustomerId,
            };
            helpers.setStatus(FormStatus.Submitting);
            setErrorMessage('');
            const updateCustomerPayload: StripeCustomerPayload = {
              stripeId: stripeCustomerId,
              email: transaction.email,
              name: `${transaction.first_name} ${transaction.last_name}`,
            };
            stripeUpdateCustomer(updateCustomerPayload, {
              onSuccess: () => {
                setSegmentCookie(transaction, transactionDetails);
                sendBillingInfo(payload, {
                  onSuccess: async () => {
                    // Go to Portal confirmation page
                    window.location.href = `${portalConfirmationPageUrl}?email=${encodeURIComponent(
                      transactionDetails.email || '',
                    )}&transaction_id=${transactionDetails.id}`;
                  },
                  onError: async () => {
                    helpers.setStatus(FormStatus.Error);
                    setErrorMessage('An error occurred while attempting to accept the Terms of Service.');
                  },
                });
              },
              onError: (error: any) => {
                setErrorMessage('Failed to update customer. Please try again.');
                helpers.setStatus(FormStatus.Error);
              },
            });
          }}
        >
          <TermsCopyContainer errorMessage={errorMessage} />
        </Formik>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ProgressStepper />
      <Formik
        initialValues={UBSInitialValues}
        validationSchema={
          isEsmSelfServe && accountDetailsAreNotPrepopulated ? UBSSelfServeValidationSchema : UBSValidationSchema
        }
        onSubmit={handleFormSubmit}
      >
        <>
          <StyledBox>
            <ErrorAlert message={errorMessage} />
            <StyledForm id="billingForm">
              {isEsmSelfServe && accountDetailsAreNotPrepopulated ? (
                <>
                  <StyledAccountHeader variant="h3">Account Details</StyledAccountHeader>
                  <FormTextField label="Account First name" name="accountFirstName" maxChars={256} />
                  <FormTextField label="Account Last name" name="accountLastName" maxChars={64} />
                  <FormTextField label="Account Email" name="accountEmail" maxChars={64} />
                  <FormTextField label="Company (optional)" name="company" maxChars={64} />
                  <FormTextField label="Phone Number (optional)" name="phoneNumber" maxChars={64} />
                </>
              ) : (
                false
              )}
              <StyledBillingHeader variant="h3">Payment Details</StyledBillingHeader>
              <CustomPaymentElement options={{ fields: { billingDetails: 'never' } }} />
              <FormTextField label="First name" name="firstName" maxChars={256} />
              <FormTextField label="Last name" name="lastName" maxChars={64} />
              <FormTextField label="Email" name="email" maxChars={64} />
              <FormTextField label="Billing address" name="billingAddress" maxChars={256} />
              <FormTextField label="Billing address 2 (optional)" name="billingAddress2" maxChars={64} />
              <CountryRegionVatSelector />
              <FormTextField label="City" name="city" maxChars={64} />
              <FormTextField label="Zip / Postal code" name="zip" maxChars={64} />
            </StyledForm>
            <ReloadModal open={ReloadModalOpen} handleClose={handleReloadModalClose} />
          </StyledBox>
          <Sidebar />
        </>
      </Formik>
    </PageContainer>
  );
};

export default StripeUBSPaymentPage;
