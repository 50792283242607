import { styled } from '@material-ui/core';
import Sidebar from 'components/Sidebar';
import { colors } from 'constants/colors';
import ErrorAlert from './ErrorAlert';
import { Form } from 'formik';

const StyledBox = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: '2',
  gridArea: 'form',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.polar10}`,
  padding: '30px 25px',
});

const StyledForm = styled(Form)(({ theme }) => ({
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
}));

interface TermsCopyContainerProps {
  errorMessage: string | undefined;
}

const TermsCopyContainer = ({ errorMessage }: TermsCopyContainerProps): JSX.Element => {
  return (
    <>
      <StyledBox>
        <ErrorAlert message={errorMessage} />
        <StyledForm id="billingForm">
          <div
            style={{
              fontSize: '2em',
              marginTop: '4em',
              textAlign: 'center',
            }}
          >
            Please accept our Terms of Service
          </div>
        </StyledForm>
      </StyledBox>
      <Sidebar />
    </>
  );
};

export default TermsCopyContainer;
