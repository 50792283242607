import { Typography, Box, styled } from '@material-ui/core';

const TotalBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const PriceBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: '10px',
});

const TotalPrice = styled(Typography)({
  fontSize: '30px',
  fontWeight: 'bold',
});

interface Props {
  total: string;
  recurrence?: string | null;
  tax?: string | null;
}

const CartTotal = ({ total, recurrence, tax }: Props) => {
  return (
    <TotalBox>
      <Typography variant="body1">Today's Total</Typography>
      <PriceBox>
        <TotalPrice>{total}</TotalPrice>
        {recurrence && (
          <Typography variant="body1">{recurrence.toLowerCase()}</Typography>
        )}
      </PriceBox>
      {!tax && (
        <Typography variant="subtitle1">*Taxes and overages may apply</Typography>
      )}
    </TotalBox>
  );
};

export default CartTotal;
