import { Box, styled } from '@material-ui/core';
import CartTotal from './CartTotal';
import { QueryStatus } from 'constants/queryStatus';
import { useCart } from 'hooks/useCart';
import { colors } from 'constants/colors';

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  gap: '20px',
  padding: '16px',
  paddingTop: '36px',
  paddingBottom: '36px',
  border: `solid 1px ${colors.polar10}`,
  borderRadius: '16px',
});

const UpdateBillingCartSummary = () => {
  const { status, cart } = useCart();

  if (status !== QueryStatus.Success) {
    return null;
  }

  return (
    <StyledBox>
      <CartTotal total={cart.total} />
    </StyledBox>
  );
};

export default UpdateBillingCartSummary;
